interface IImageLoader {
	src: string | JSX.Element;
	width?: number;
}

const imageLoader = ({ src, width }: IImageLoader) => {
	if (width) {
		return `${src}?w=${width}`;
	}
	return `${src}`;
};

export default imageLoader;
