import { Key, SortOrder } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { optionAll } from '@/constants/option';
import { COMMON_LEVEL, COMMON_LEVEL_NAME } from '@/constants/program';
import { COURSE_RESOURCE_TYPE, COURSE_RESOURCE_TYPE_TEXT, FILE_TYPE } from '@/constants/resource';
import {
	Country,
	CurrentStatus,
	Gender,
	WORK_EXPERIENCE,
	WORK_EXPERIENCE_OPTIONS
} from '@/constants/user';

dayjs.extend(utc);
dayjs.extend(timezone);

const sydneyTz = 'Australia/Sydney';

/**
 * 获取包含“全部”的options
 */
export const getAllOptions = (options?: IOption[]) => {
	if (!options) {
		return [optionAll];
	}
	return [optionAll, ...options];
};

/**
 * 获取课程授课方式
 * @param city city
 * @returns 'Online' | 'On Campus'
 */
export const getProgramTeachingMethod = (city: string) => {
	return city === 'Online' ? 'Online' : 'On Campus';
};

export const getGenderText = (gender: Gender) => {
	switch (gender) {
		case Gender.MALE:
			return '男';
		case Gender.FEMALE:
			return '女';
		default:
			return '其他';
	}
};

export const getGenderOptions = (): ISelectOptions[] => {
	return Object.values(Gender).map(value => {
		return {
			label: getGenderText(value),
			value
		};
	});
};

export const getWorkExperienceOptions = (): ISelectOptions[] => {
	return Object.values(WORK_EXPERIENCE).map(value => {
		return {
			label: WORK_EXPERIENCE_OPTIONS[value],
			value: WORK_EXPERIENCE_OPTIONS[value]
		};
	});
};

export const getLevelOptions = (): ISelectOptions[] => {
	return Object.values(COMMON_LEVEL).map(value => {
		return {
			label: COMMON_LEVEL_NAME[value],
			value: COMMON_LEVEL_NAME[value] // db保存的中文
		};
	});
};

export const getCourseResourceTypeOptions = (): ISelectOptions[] => {
	return Object.values(COURSE_RESOURCE_TYPE).map(value => {
		return {
			label: COURSE_RESOURCE_TYPE_TEXT[value],
			value
		};
	});
};

export const getFileTypeOptions = (): ISelectOptions[] => {
	return Object.entries(FILE_TYPE).map(fileType => {
		return {
			label: fileType[0],
			value: fileType[1]
		};
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cleanObject = (obj: { [key: string]: any }) => {
	Object.keys(obj).forEach(key => {
		if (!obj[key]) {
			delete obj[key];
		}
	});
	return obj;
};

export const getTimeZoneCityCN = (city: string) => {
	if (city === 'Sydney') {
		return '悉尼时间';
	}
	if (city === 'Shanghai') {
		return '北京时间';
	}
	return '';
};

export const getSalesWorkHourZone = (hour: number) => {
	const timeZone = dayjs.tz.guess();
	return (
		dayjs()
			.tz(sydneyTz)
			.hour(hour - 1)
			.tz(timeZone)
			.hour() + 1 // .hour() 0-23
	);
};

export const getIsSalesInWork = (startHour: number | undefined, endHour: number | undefined) => {
	if (!startHour || !endHour) {
		return false;
	}

	return dayjs().tz(sydneyTz).hour() >= startHour && dayjs().tz(sydneyTz).hour() < endHour;
};
/**
 * 获取sales的底部work文案
 * 有description返回description
 * 没有返回转换的时间
 * 也没配置时间无返回
 */
export const getSalesWorkStr = (
	description: string | undefined,
	startHour: number | undefined,
	endHour: number | undefined
) => {
	if (description) {
		return description;
	}
	if (startHour && endHour) {
		const timeZone = dayjs.tz.guess();
		const start = getSalesWorkHourZone(startHour);
		const end = getSalesWorkHourZone(endHour);
		return `*周一-周五工作日时间：${start}点到${end}点（${getTimeZoneCityCN(
			timeZone?.split('/')[1] || 'Sydney'
		)}）`;
	}
	return '';
};
export const getUserFullName = (firstName: string, lastName: string) => {
	return !firstName && !lastName ? 'User' : `${firstName || ''} ${lastName || ''}`;
};

export const blobToFile = (theBlob: Blob, prefix?: string): File => {
	const lastModified = Number(new Date());
	const fileName = prefix ? `${prefix}-${lastModified}` : `${lastModified}`;
	const file: File & { thumbUrl?: string } = new File([theBlob], fileName, {
		lastModified,
		type: 'image/png'
	});
	return file;
};

export const getCountryText = (country: Country) => {
	switch (country) {
		case Country.AUSTRALIA:
			return '澳大利亚';
		case Country.CHINA:
			return '中国';
		case Country.SINGAPORE:
			return '新加坡';
		default:
			return '其他';
	}
};

export const getCountryOptions = (): ISelectOptions[] => {
	return Object.values(Country).map(value => {
		return {
			label: getCountryText(value),
			value
		};
	});
};

export const getCurrentStatusText = (currentStatus: CurrentStatus) => {
	switch (currentStatus) {
		case CurrentStatus.UNIVERSITY_STUDENT:
			return '大学在校生';
		case CurrentStatus.NEW_STUDENT:
			return '新生入学';
		case CurrentStatus.GRADUATED:
			return '毕业求职';
		case CurrentStatus.WORKING:
			return '在职人士';
		default:
			return '在职人士';
	}
};

export const getCurrentStatusOptions = (): ISelectOptions[] => {
	return Object.values(CurrentStatus).map(value => {
		return {
			label: getCurrentStatusText(value),
			value
		};
	});
};

export const getQuerySort = (field: Key | readonly Key[], order: SortOrder): string => {
	if (!field) {
		return '';
	}
	if (Array.isArray(field)) {
		return '';
	}
	if (order === 'descend') {
		return `-${field}`;
	}
	if (order === 'ascend') {
		return `+${field}`;
	}
	return '';
};

export default {};
