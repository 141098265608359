import { CAREER_COMMUNITY_TYPE, CAREER_COMMUNITY_TYPE_TEXT } from './careerCommunity';
import { TIME_ZONES } from './common';
import { LESSON_DURATION } from './lesson';
import TRAINING_TYPE_LABEL, { ETrainingType } from './training';
import { getTimeZoneWithOffset } from '@/utils/date';

export const trainingTypeOptions: IOption[] = [
	{
		label: TRAINING_TYPE_LABEL[ETrainingType.TRAINING],
		value: ETrainingType.TRAINING
	},
	{
		label: TRAINING_TYPE_LABEL[ETrainingType.VIDEO],
		value: ETrainingType.VIDEO
	},
	{
		label: TRAINING_TYPE_LABEL[ETrainingType.INTERNSHIP],
		value: ETrainingType.INTERNSHIP
	}
];

export const careerCommunityTypeOptions: IOption[] = [
	{
		label: CAREER_COMMUNITY_TYPE_TEXT[CAREER_COMMUNITY_TYPE.TECH],
		value: CAREER_COMMUNITY_TYPE.TECH
	},
	{
		label: CAREER_COMMUNITY_TYPE_TEXT[CAREER_COMMUNITY_TYPE.HOBBIES],
		value: CAREER_COMMUNITY_TYPE.HOBBIES
	}
];

export const optionAll: IOption = {
	label: '全部',
	value: ''
};

export const allTZOptions = TIME_ZONES.map(tz => {
	return {
		label: getTimeZoneWithOffset(tz),
		value: tz
	};
});

export const lessonDurationOptions = () => {
	return LESSON_DURATION.map(item => {
		return {
			label: item,
			value: item
		};
	});
};

export default {};
