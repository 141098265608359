export enum ETrainingType {
	TRAINING = 'training', // Live Bootcamp
	VIDEO = 'video', // Online Video Training, no deadline and commence date
	INTERNSHIP = 'internship' // Program Internship
}

const TRAINING_TYPE_LABEL: Record<ETrainingType, string> = {
	[ETrainingType.TRAINING]: '训练营',
	[ETrainingType.VIDEO]: '视频课程',
	[ETrainingType.INTERNSHIP]: '项目实训营'
};

export enum EBootcampTab {
	DESCRIPTION = 'description',
	SCHEDULE = 'schedule'
}

export enum EBootcampSlug {
	WEB_FULL_STACK = 'web-code-bootcamp-or-learn-to-code-1',
	DEVOPS = 'devops',
	DATA_ANALYST = 'data-analyst-power-bi-python-excel',
	DATA_ENGINEER = 'data-engineer-data-analyst'
}

export enum EBootcampDetailNav {
	/** 课程介绍 */
	INTRODUCTION = 'introduction',
	/** 课程安排 */
	SCHEDULE = 'class-schedule',
	/** 职业前景 */
	OUTLOOK = 'career-prospect'
}

export const BOOTCAMP_DETAIL_NAV_LABEL: Record<EBootcampDetailNav, string> = {
	[EBootcampDetailNav.INTRODUCTION]: '课程介绍',
	[EBootcampDetailNav.SCHEDULE]: '课程安排',
	[EBootcampDetailNav.OUTLOOK]: '职业前景'
};

export default TRAINING_TYPE_LABEL;
