const sizes: ISize = {
	miniMobile: 320,
	smallMobile: 414,
	mobile: 576,
	tablet: 768,
	laptop: 1024,
	largeLaptop: 1440,
	desktop: 1920,
	largeDesktop: 2560,
	wideScreen: 3840
};

const devices: IDevices = {
	miniMobile: `(min-width: ${sizes.miniMobile}px)`,
	smallMobile: `(min-width: ${sizes.smallMobile}px)`,
	mobile: `(min-width: ${sizes.mobile}px)`,
	tablet: `(min-width: ${sizes.tablet}px)`,
	laptop: `(min-width: ${sizes.laptop}px)`,
	largeLaptop: `(min-width: ${sizes.largeLaptop}px)`,
	desktop: `(min-width: ${sizes.desktop}px)`
};

const pagePaddings: IPagePaddings = {
	mobile: '16px',
	tablet: '24px'
};

const color: IColor = {
	primaryColor: '#ff5757',
	primaryBackgroundColor: '#ffebeb',
	textColorBlack: '#23262b',
	textColor: '#535353',
	textSecondaryColor: '#666666',
	textColorLight: '#a8a8a8',
	textColorDark: '#7e7e7e',
	textColorGrey: '#545454',
	whiteColor: '#ffffff',
	greyColorDark: '#90aae1',
	greyColorLight: '#f5f5f5',
	greyBackgroundColor: '#f6f8f9',
	backgroundColorDark: '#f8faff',
	placeHolderColor: '#c0c6d4',
	borderColor: '#c4d0ea',
	borderColorLight: '#efefef',
	shadowColor: 'rgba(0, 0, 0, 0.1)',
	primaryButtonShadow: 'rgba(255, 87, 87, 0.44)',
	scrollBarColor: '#e5e5e5',
	footerLinkColor: '#999999',
	bgColor: '#fff1e7',
	blackColor: '#000000',
	logoBgColor: '#ff6957',
	greyBgColor: '#f8f8f8',
	materialBgColor: '#f9f9f9',
	materialBorderColor: '#e9e9e9',
	wikiSectionBorderColor: '#dcd1c9',
	wikiRegisterButtonColor: '#10162f',
	// study page
	primaryColorHover: '#ffc4c4',
	primaryYellow: '#fdecc6',
	primaryLightYellow: '#fffae5',
	primaryBlue: '#d3f2ff',
	primaryGreen: '#eafec6',
	primaryRed: '#ffd3d3',
	primaryPurple: '#d3dcff',
	primaryDark: '#0a0d1c',
	labelBgColorYellow: '#ffe400',
	labelBgColorGreen: '#d5ff57',
	tagColor: '#fffdfa',
	selectedTagColor: '#f6dfbb',
	adminColor: '#247fff',
	pendingIconColor: '#ffa900',

	// button
	buttonDefault: '#10162f',
	buttonDefaultHover: '#303342',
	buttonWhite: '#ffffff',
	buttonBeige: '#fdecc6',
	buttonLightBlue: '#d3f2ff'
};

export { color, devices, pagePaddings, sizes };
