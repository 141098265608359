/**
 * @description
 * Check whether it is client side render, to make sure window is defined
 * @return {boolean}
 */

export const checkCSR = (): boolean => typeof window !== 'undefined';

export const checkLogin = (): boolean => checkCSR() && Boolean(localStorage.getItem('token'));

export const isIOS = () => {
	return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const isMobile = () => {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);
};

export default {};
