import { color } from '@/styles/variables';

const { primaryColor, labelBgColorYellow, labelBgColorGreen } = color;

export enum CAREER_COMMUNITY_TYPE {
	TECH = 'tech',
	HOBBIES = 'hobbies'
}

export const CAREER_COMMUNITY_TYPE_TEXT = {
	[CAREER_COMMUNITY_TYPE.TECH]: '技术类',
	[CAREER_COMMUNITY_TYPE.HOBBIES]: '兴趣类'
};

export const BadgeZhNameAndColorMap: { [key: string]: { zhName: string; bgColor: string } } = {
	hot: {
		zhName: '热门',
		bgColor: primaryColor
	},
	featured: {
		zhName: '精选',
		bgColor: labelBgColorYellow
	},
	new: {
		zhName: '新群',
		bgColor: labelBgColorGreen
	}
};
