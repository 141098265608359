export enum EProgramType {
	TRAINING = 'training',
	VIDEO = 'video',
	INTERNSHIP = 'internship'
}

export const PROGRAM_TYPE_NAME = {
	[EProgramType.TRAINING]: '训练营',
	[EProgramType.VIDEO]: '视频课程',
	[EProgramType.INTERNSHIP]: '项目实训营'
};

export enum COMMON_LEVEL {
	ENTRY = 'entry',
	JUNIOR = 'junior',
	INTERMEDIATE = 'intermediate',
	INTERMEDIATE_ADVANCE = 'intermediateAdvance',
	ADVANCED = 'advance'
}

export const COMMON_LEVEL_NAME = {
	[COMMON_LEVEL.ENTRY]: '零基础',
	[COMMON_LEVEL.JUNIOR]: '初级',
	[COMMON_LEVEL.INTERMEDIATE]: '中级',
	[COMMON_LEVEL.INTERMEDIATE_ADVANCE]: '中级进阶',
	[COMMON_LEVEL.ADVANCED]: '高级'
};

export enum EProgramCardTagContent {
	NEWEST = '最新',
	AboutToStart = '即将开课'
}

export enum EProgramCardTimeLengthTitle {
	PROGRAM_TIME_LENGTH = '课程时长',
	PROJECT_TIME_LENGTH = '项目时长'
}

export default EProgramType;
