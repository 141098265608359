import queryString from 'query-string';

import { checkCSR } from './check';
import { cleanObject } from './common';

export const pushHistoryState = ({
	url,
	queryParams,
	stringQuery
}: {
	url?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	queryParams?: { [key: string]: any };
	stringQuery?: string;
}) => {
	if (checkCSR()) {
		if (url) {
			window.history.pushState(null, '', url);
		} else {
			const query = stringQuery || queryString.stringify(cleanObject(queryParams || {}));
			window.history.pushState(null, '', `${window.location.pathname}?${query}`);
		}
	}
};

export default pushHistoryState;
