export const LESSON_TYPES_LABEL = {
	Lesson: 'Lecture',
	Tutorial: 'Tutorial',
	Video: '视频课程',
	Information: 'Information',
	Workshop: 'Workshop',
	Assignment: 'Assignment',
	Quiz: 'Quiz',
	Lab: 'Lab'
};

export enum ELessonTypes {
	LESSON = 'Lesson',
	TUTORIAL = 'Tutorial',
	VIDEO = 'Video',
	INFORMATION = 'Information',
	WORKSHOP = 'Workshop',
	ASSIGNMENT = 'Assignment',
	QUIZ = 'Quiz',
	LAB = 'Lab'
}

export enum ELessonProgressStatus {
	NOT_STARTED = 'not_started',
	COMPLETED = 'completed'
}

export const LESSON_PROGRESS_STATUS_LABEL = {
	not_started: '未学',
	completed: '已学'
};

export enum ELessonRenderMode {
	LIST = 'list',
	MODULE = 'module',
	CALENDAR = 'calendar'
}

export const LESSON_RENDER_MODE_LABEL = {
	list: '大纲模式',
	module: '模块模式',
	calendar: '日历模式'
};

export default LESSON_TYPES_LABEL;

export enum EStudyLessonTab {
	CONTENT = 'content',
	MATERIAL = 'material',
	NOTE = 'note'
}

export const STUDY_LESSON_TAB_LABELS = {
	[EStudyLessonTab.CONTENT]: '课程内容',
	[EStudyLessonTab.MATERIAL]: '课程资料',
	[EStudyLessonTab.NOTE]: '课程笔记'
};

export enum LESSON_MODULE_TYPE {
	VIDEO = 'video',
	TEXT = 'text',
	FILES = 'files'
}

/**
 * 当lesson module为file类型时，具体添加内容的类型
 */
export enum LESSON_MODULE_FILE_TYPE {
	URL = 'url',
	VIDEO = 'video',
	FILE = 'file'
}

export enum LESSON_MODULE_EDIT_TYPE {
	UPDATE = 'update',
	DELETE = 'delete'
}

export const OPENED_EVALUATION = 'openedEvaluation';

export const BAR_STICKY_ID = 'side-nav-container';

export const LESSON_IS_LIVE_LABEL = {
	isLive: '已开启',
	notLive: '未开启'
};

export const LESSON_DURATION = ['30', '60', '90', '120', '150', '180', '240'];
